<!--
 * @Author       : your name
 * @Date         : 2020-11-20 21:53:08
 * @LastEditTime : 2020-11-30 23:51:39
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/header/Header.vue
-->
<template>
  <div class="header-container">
  <transition name="fadeIn" enter-active-class="fadeInDown">
    <div v-if="isMobile" class="mobile-header">
      <div style="" class="head">
        <img @click="jumpHome" src="../../assets/img/common/logo.png" alt="" class="logo">
        <div class="handles">
          <div @click="showCall = true" class="phone">
          </div>
          <div @click="menuHandle" :class="[showMenu ? 'close' : '','menu']">
          </div>
        </div>
      </div>
      <div @click.stop="showCall = false" v-if="showCall" class="call">
        <div @click.stop="showCall = true" class="content">
          <div class="title">拨打电话</div>
          <div class="tel">
            <a href="tel:400-172-6888">400-172-6888</a>
          </div>
          <!-- <div class="tel">
            <a href="tel:400-811-0606">400-811-0606</a>
          </div> -->
          <div @click.stop="showCall = false" class="cancel" style="color: #333333;">取消</div>
        </div>
      </div>
      <div v-show="showMenu" class="nav-content">
        <div class="search-box">
          <div class="search">
            <input v-model="keywords" type="text">
            <div @click="jumpSearch" class="search-btn"></div>
          </div>
        </div>
        <div class="nav-box first">
          <div v-for="(item, index) in mobileNavs" :key="index" :class="[item.show ? 'show' : '', 'nav-item', 'first-level']">
            <div @click="jumpPage(item)" :class="[item.name == currentPageName ? 'active' : '','nav-name']">
              <span class="name">{{item.name}}</span>
              <img v-if="item.children && item.show" src="../../assets/img/common/cut.png" alt="">
              <img v-else-if="item.children && !item.show" src="../../assets/img/common/plus.png" alt="">
            </div>
            <div v-if="item.children && item.show" class="nav-box second">
              <div v-for="(val, key) in item.children" :key="key" class="nav-item">
                <div @click="jumpPage(val)" :class="[val.name == currentPageName ? 'active' : '','nav-name']">{{val.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <transition name="fadeIn" enter-active-class="fadeInDown">
        <header v-show="showBanner">
          <b-navbar
            toggleable="md"
            type="light"
            fixed
          >
            <b-navbar-brand href="/">
              <img src="@/assets/img/common/logo.png" alt="" class="logo" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav class="left-nav">
              <b-navbar-nav>
                <b-nav-item
                  :href="item.link"
                  v-for="item of navs"
                  :key="item.link"
                  :class="
                    item.navName === navName ||
                    currentPath === item.link ||
                    ((currentPath === '/#/aboutUs/responsibility' ||
                      currentPath === '/#/aboutUs/news') &&
                      item.link === '/#/aboutUs/Introduction')
                      ? 'active'
                      : ''
                  "
                  >{{ item.name }}</b-nav-item
                >
                <b-nav-item href="#" class="right-container">
                  <img
                    src="@/assets/img/common/nav-phone.png"
                    alt=""
                    class="nav-phone"
                    id="phone"
                    @mouseenter="moveIn"
                    @mouseleave="moveOut"
                  />
                  <div
                    class="nav-search-container"
                    @click="$router.push({ name: 'searchIndex' })"
                  >
                    <img
                      src="@/assets/img/common/nav-search.png"
                      alt=""
                      class="nav-search"
                    />
                    <span
                      class="triange-icon"
                      v-if="$route.name === 'searchIndex'"
                    ></span>
                  </div>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
          <transition
            name="fadeIn"
            enter-active-class="fadeIn"
            leave-active-class="fadeOut"
          >
            <div
              class="phone-detail"
              v-show="showPhone"
              @mouseenter="moveIn"
              @mouseleave="moveOut"
            >
              <p class="title">全国统一客服专线（投诉与建议）</p>
              <p><img src="@/assets/img/common/p-o.png" />400-172-6888</p>

              <!-- <p><img src="@/assets/img/common/p-o.png" />400-811-0606</p> -->
            </div>
          </transition>
        </header>
      </transition>
    </div>
  </transition>
  </div>
</template>

<script>
import { EventBus } from '@/helper/eventBus'
import { getUrlRelativePath, isMobile } from '@/helper/utils'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    time: {
      type: Number,
      default: 2000
    },
    navName: {
      type: String,
      default: '/'
    }
  },
  data () {
    return {
      showCall: false,
      showPhone: false,
      keywords: '',
      showSearch: false,
      showBanner: false,
      timer: null,
      navs: [
        { name: '首页', link: '/#/' },
        { name: '关于明亚', link: '/#/aboutUs/Introduction', navName: 'aboutUs' },
        { name: '客户服务', link: '/#/customerService/agreement', navName: 'customerService' },
        { name: '精英风采', link: '/#/eliteStyle/hof/member', navName: 'eliteStyle' },
        { name: '信息披露', link: '/#/mechanism', navName: 'mechanism' },
        { name: '联系我们', link: '/#/contactus/organization', navName: 'contactus' }
      ],
      showMenu: false,
      currentPageName: '首页',
      mobileNavs: [
        { name: '首页', link: '/'},
        {
          name: '关于明亚',
          children: [
            { name: '明亚简介', link: '/aboutUs/Introduction' },
            { name: '明亚文化', link: '/aboutUs/Culture' },
            { name: '明亚荣誉', link: '/aboutUs/Honor' },
            { name: '社会责任', link: '/aboutUs/responsibility' },
            { name: '新闻动态', link: '/aboutUs/news' },
            { name: '媒体聚焦', link: '/aboutUs/Media' },
            { name: '明亚年报', link: '/aboutUs/report' }
          ]
        },
        {
          name: '客户服务',
          children: [
            { name: '经纪服务委托协议', link: '/customerService/agreement' },
            { name: '客户告知书', link: '/customerService/notification' },
            { name: '特色服务', link: '/customerService/special' },
            { name: '保单服务', link: '/customerService/policy' },
          ]
        },
        {
          name: '精英风采',
          children: [
            { name: '名人堂会员', link: '/eliteStyle/hof/member' },
            { name: '名人汇', link: '/eliteStyle/hof/club' },
            { name: '明亚高峰会', link: '/eliteStyle/meeting' },
            { name: '明亚精英', link: '/eliteStyle/elite' },
            { name: '明亚生活', link: '/eliteStyle/life' }
          ]
        },
        {
          name: '信息披露',
          children: [
            { name: '合作机构', link: '/mechanism' },
            { name: '互联网保险', link: '/OnlineInsurance' },
            { name: '产品披露', link: '/disclosure' },
            { name: '系统介绍', link: '/systemintroduction' }
          ]
        },
        {
          name: '联系我们',
          children: [
            { name: '机构分布', link: '/contactus/organization' },
            { name: '招贤纳士', link: '/contactUs/recruit' },
            { name: '明亚自媒体', link: '/contactUs/media' }
          ]
        },
      ],
      currentPath: getUrlRelativePath(),
      isMobile: isMobile()
    }
  },
  mounted () {
    EventBus.$on('router-change', () => {
      this.currentPath = getUrlRelativePath()
    })
  },
  watch: {
    show (val) {
      const notDown = sessionStorage.getItem('notDown')
      const relativePath = getUrlRelativePath()
      if (val && (!notDown || relativePath === '/#/')) {
        sessionStorage.setItem('notDown', true)
        setTimeout(() => {
          this.showBanner = true
          if (isMobile()) {
            var headerHeight = document.querySelector('.mobile-header .head').offsetHeight
            document.getElementById('app').style.paddingTop = headerHeight + 'px'
          } else {
            document.getElementById('app').style.paddingTop = '0.85rem'
          }
          
        }, this.time)
      } else if (val) {
        this.showBanner = true
      }
    }
  },
  created () {},
  methods: {
    // 返回首页
    jumpHome () {
      this.$router.push({ path: '/' })
    },
    // 跳转搜索页
    jumpSearch () {
      this.$router.push({ path: '/search', query: { keyword: this.keywords } })
    },
    // 移动端导航栏的显示和隐藏
    menuHandle () {
      // document.querySelector('html').style.fontSize = '71px'
      this.currentPageName = this.$route.meta.title
      this.showMenu = !this.showMenu
      let _this = this
      this.mobileNavs.forEach(item => {
        item.show = false
        if (item.children) {
          item.children.forEach(val => {
            if (val.name == _this.currentPageName) {
              item.show = true
            }
          })
        }
        
      })
    },
    jumpPage (navItem) {
      if (navItem.link) {
        this.showMenu = false
        this.$router.push({ path: navItem.link })
      } else {
        if (navItem.show === true) {
          navItem.show = false
          this.$forceUpdate()
        } else {
          this.mobileNavs.forEach(item => {
            if (item.name == navItem.name) {
              item.show = true
            } else {
              item.show = false
            }
          })
          this.$forceUpdate()
        }
      }
    },
    hideSecondNav (item) {
      item.show = false
      this.$forceUpdate()
    },
    showSecondNav (nav) {
      this.mobileNavs.forEach(item => {
        if (item.name == nav.name) {
          item.show = true
        } else {
          item.show = false
        }
      })
      this.$forceUpdate()
    },
    moveIn () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.showPhone = true
    },
    moveOut () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.showPhone = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
@keyframes actionsheet {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.call {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  left: 0;
  bottom: 0;
  overflow: hidden;
  .content {
    animation: actionsheet .3s forwards;
    transform: translateY(100%);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #EFEFF4;
    .title, .tel, .cancel {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      background-color: #ffffff;
      color: #888888;
      a {
        color: #FE6917;
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .title {
      height: 48px;
    }
    .tel {
      border-top: 1px solid #e5e5e5;
    }
    .cancel {
      margin-top: 6px;
    }
  }
}
.mobile-header {
  min-height: 1.05em;
  font-size: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000000;  
  background-color: #ffffff;
  .nav-content {
    .nav-box.first {
      flex: 1;
      overflow-y: scroll;
    }
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.05em;
    display: flex;
    flex-direction: column;
    .nav-name {          
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 .3em;
      &.active {
        background-color: #FFF8EC;
        color: #FE8D22!important;
      }   
      .name {
        font-size: .32em;
      }     
      img {
        width: .2em;
      }
    }
    .nav-item {
      &.show {
        > .nav-name {
          border-color: #FE6917;
          background-color: #FFF8EC;
        }
      }
      .nav-name {
        border-top: 1px solid #E5E5E5;
        &:hover {
          background-color: #FFF8EC;
        }
      }
      &:first-child {
        .nav-name {
          border: 0 none;
        }
        border-top: 0 none;
      }
      &:last-child {
        border-bottom: 1px solid #E5E5E5;       
      }
      box-sizing: border-box;
      
      line-height: 1.22em;
      .nav-item {
        line-height: .8em;
        border: 0 none;
        .nav-name {
          padding: 0 2em;
          font-size: .3em;
          color: #666666;
          border: 0 none;

        }
      }
    }
  }

  .search-box {
    height: 1.7em;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 .6rem;
    .search {
      width: 100%;
      height: 0.8em;
      border-radius: 0.4em;
      border: 1px solid #BCBDBF;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      padding-right: 0.12em;
      padding-left: .3em;
      input {
        flex: 1;
        width: 100%;
        height: 100%;
        border: 0 none;
        outline: none;
        font-size: 0.3em;
        &:focus {
          border: 0 none;
          outline: none;
        }
      }
      .search-btn {
        margin-left: .3em;
        width: .56em;
        height: .56em;
        background-image: url(../../assets/img/common/search_btn.png);
        background-size: 100% 100%;
      }
    }
  }
  .head {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    box-sizing: border-box;
    padding: 0 0.3em;
    width: 100%;
    height: 1.05em;
    .logo {
      width: 2.08em;
      height: auto;
    }
    .handles {
      display: flex;
      align-items: center;
      height: 100%;    
      .phone {
        height: .38em;
        width: .38em;
        margin-right: .6em;
        position: relative;
        background-image: url(../../assets/img/common/phone.png);
        background-size: 100% 100%;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: .26em;
          background-color: #E6E6E6;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -0.22em;
        }
      }
      .menu {
        width: .3em;
        height: .24em;
        background-image: url(../../assets/img/common/menu.png);
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        &.close {
          background-image: url(../../assets/img/common/menu_close.png);
        }
      }
    }
  } 
  
}
.hidden {
  display: none;
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.phone-detail {
  position: absolute;
  bottom: -1.65rem;
  right: 1.1rem;
  width: 2.94rem;
  height: 1.2rem;
  background-image: url('../../assets/img/common/phone-bg.png');
  background-size: 100% 100%;
  padding: 0.2rem;
  padding-top: 1.6%;
  font-size: 0.16rem;
  .title {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.1rem;
  }
  img {
    width: 0.14rem;
    height: 0.14rem;
    margin-right: 0.1rem;
    vertical-align: middle;
  }
  p {
    color: #ff923b;
    margin-bottom: 0;
    line-height: 0.28rem;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@media screen and (max-width: 845px) {
  header nav.navbar {
    padding-left: 0 !important;
  }
  header .nav-item {
    text-align: center;
  }
  header .navbar-toggler:focus {
    box-shadow: none !important;
  }
}
.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.tooltip {
  background-color: #fff;
  color: #333;
}
.tooltip-inner {
  font-size: 0.16rem;
  background-color: #fff;
  color: #333;
}
header {
  height: 0.85rem;
  line-height: 0.85rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  .navbar-toggler {
    font-size: 0.2rem;
    padding: 0.16rem 0.5rem;
    border-radius: 0.08rem;
    margin-left: auto;
    margin-right: 0.2rem;

    &:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: 0 0 0 0.04rem;
    }
  }
  .nav-item {
    background-color: #fff;
  }
  .search-input {
    position: absolute;
    right: 0.1rem;
    width: 3.2rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    padding-left: 0.44rem;
    border-radius: 0.04rem;
    bottom: -0.6rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    &::placeholder {
      color: #cccccc;
      font-size: 0.14rem;
    }
  }

  nav.navbar {
    position: relative;
    padding: 0;
    height: 100%;
    padding: 0;

    .navbar-brand {
      position: absolute;
      top: 0;
      left: 0;
      width: 3.4rem;
      height: 1.85rem;
      background-image: url('../../assets/img/common/logo-circle.png');
      background-size: 100% 100%;
      z-index: 10;
      .logo {
        width: 2.08rem;
        height: 0.4rem;
        position: absolute;
        top: 0.44rem;
        left: 0.26rem;
      }
    }
    .left-nav {
      width: 100%;
      height: 0.85rem;
    }

    .navbar-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 0.85rem;
      padding: 0;
      margin: 0;
      padding-left: 4rem;
      align-items: center;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
      .nav-phone {
        width: 0.33rem;
        height: 0.33rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }
      .right-container {
        .nav-link {
          display: flex;
          align-items: center;
          &:hover {
            color: #ff923b;
          }
        }
        .nav-search-container {
          position: relative;
          width: 1.09rem;
          height: 0.85rem;
          overflow: hidden;
          display: inline-block;
          .triange-icon {
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: pxToRem(-12);
            border-left: pxToRem(12) solid transparent;
            border-right: pxToRem(12) solid transparent;
            border-bottom: pxToRem(12) solid #fff;
          }
        }
        .nav-search {
          display: flex;
          justify-content: space-between;
          align-items: center;

          cursor: pointer;
          width: 1.09rem;
          height: 0.85rem;
          transition: all 0.6s;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }
  .dropdown-item {
    // display: none;
    // position: absolute;
    // z-index: 100;
    transition: all 0.6s;
    &:hover {
      background-color: #ff923b;
      color: #fff;
    }
    &:active {
      background-color: $orange;
      border: none;
      outline: none;
    }
  }
  .nav-link {
    color: #333 !important;
    font-size: 0.14rem;
    padding: 0 !important;
    cursor: pointer;
  }
  .navbar-light .navbar-nav .nav-item:nth-child(6) {
    // background-color: red;
    .nav-link {
      &::after {
        content: '|' !important;
        color: #cccccc;
        position: absolute;
        right: -1rem;
      }
    }
  }
  .navbar-light .navbar-nav .nav-item.active .nav-link {
    color: #ff923b !important;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 0 !important;
    color: #333 !important;
    position: relative;
    &:hover {
      color: #ff923b !important;
    }
    &::after {
      content: '/';
      color: #cccccc;
      position: absolute;
      right: -1rem;
    }
  }
  .dropdown-menu {
    z-index: 8 !important;
    width: 100vw;
    text-align: center;
    padding: 0.08rem 0;
    overflow: hidden;
    border: none;
    border-radius: 0.08rem;
    margin-top: 0.04rem;
    position: fixed;
    left: 0;
    background-color: transparent;
    .animate {
      position: fixed;
      width: 100vw;
      height: 0.7rem;
      top: 0.85rem;
      left: 0;
      display: flex;
      background: #fff;
      justify-content: space-around;
      padding: 0 20%;
    }
    li {
      height: 0.6rem;
      line-height: 0.6rem;
      display: block;
      width: 100%;
      a {
        font-size: 0.14rem;
        padding: 0.04rem 0.16rem;
      }
    }
  }
}
</style>
